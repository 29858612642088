$(document).on "ready", ->
    CrescentHeightsSeattle.init()

CrescentHeightsSeattle =
  isLoaded: ->
    $("body").addClass "is-loaded"
    if $(".body-home").length
        window.scrollTo(0,document.body.scrollHeight)

  onLoad: ->
    CrescentHeightsSeattle.onResize()

    $(".body").imagesLoaded {}, ->
        CrescentHeightsSeattle.isLoaded()

    # Fallback in case images don't load.
    setTimeout (->
        $("body").addClass "is-loaded"
    ), 3000

  onResize: ->
    if $(".body-home").length
        if $("html").hasClass "touchevents"
            height = 620 # TODO: Make this dynamic
        else
            height = $(window).height()

        $(".home-section-slide").css "height", (height * 1.01)

  init: ->
    CrescentHeightsSeattle.onLoad()
    $(window).resize ->
        CrescentHeightsSeattle.onResize()

    # FAQ Toggle
    $(".js-toggle-next").click (event) ->
        $(this).next().toggleClass "is-visible"
        event.preventDefault()

    $(".js-open-modal").magnificPopup
        type: 'inline'
        closeBtnInside: false
        midClick: true
        mainClass: 'mfp-fade'

    $(".js-close-topic").click (event) ->
        $.magnificPopup.close();
        event.preventDefault()

    # About Page Slider
    $(".js-slider").owlCarousel
        center: true
        dots: false
        items: 1
        loop: true
        margin: 0
        nav: true
        navText: ["&larr;","&rarr;"]
        responsiveRefreshRate: 50
        responsive: 900:
            items: 2
            autoWidth: true

    # Home scroll up on click
    $(".js-scroll-up").click (event) ->
        $this = $(this)
        unless event.target.href
            $slides = $('.home-section-slide')
            $thisIndex = $slides.index $this
            $prev = $slides.eq($thisIndex - 1)
            $prev.animatescroll
                scrollSpeed: 500
            event.preventDefault()

    $(".js-scroll-top").click (event) ->
        $this = $(this)
        unless event.target.href
            $("body").animatescroll()
            event.preventDefault()

    $(".js-scroll-down").click (event) ->
        $("#content").animatescroll()
        event.preventDefault()

    # Home waypoints
    waypoints = $('.home-section-slide').waypoint(
        handler: (direction) ->
            if direction == "up"
                $(this.element).addClass "is-centered"
            return
        offset: '-50%')

    waypoints = $('.home-section-slide').waypoint(
        handler: (direction) ->
            if direction == "up"
                $(this.element).addClass "is-leaving"
            return
        offset: '5%')

class CrescentHeightsSeattle.Controller
